<template>
    <div class="modifyApply">
        <van-nav-bar
                title="申请服务费"
                left-text="返回"
                left-arrow
                fixed
                z-index=30
                @click-left="onClickLeft"
        />
        <div class="geren" v-if="co_mode==1">
            <ul>
                <li>
                    <div class="label">当前合作方式:</div>
                    <div class="val">个人</div>
                </li>
                <li>
                    <div class="label">月可申请额度:</div>
                    <div class="val">{{price}}（元）</div>
                </li>
                <li>
                    <div class="label">综合服务费率:</div>
                    <div class="val">7% <span>（1000元以下不收取）</span></div>
                </li>
                <li>
                    <div class="label">申请与发放:</div>
                    <div class="val">1-3个工作日内发放</div>
                </li>
            </ul>
        </div>
        <!-- <div class="invoiceWrap" v-if="co_mode==2">
            <div class="invoiceTitle">
                <p class="invoiceType">请选择发票类型</p><p class="invoiceExplain">发票说明</p>
            </div>
            <div class="invoiceBox">
                <div class="typeInvoice">
                    <ul>
                        <li :class="invoice_type==1?'':'active'" @click="modifyType(1)">
                            <img :src="invoice_type==1?invoiceImg:invoiceImgDefault" alt="">
                            <span :class="invoice_type==1?'':'active'">电子发票</span>
                        </li>
                        <li :class="invoice_type==2?'':'active'" @click="modifyType(2)">
                            <img :src="invoice_type==2?invoiceImg:invoiceImgDefault" alt="">
                            <span :class="invoice_type==2?'':'active'">纸质发票</span>
                        </li>
                    </ul>
                </div>
                <div class="uploadImg clearfix" v-if="invoice_type==1">
                    <div class="uploadBox fl">
                        <van-uploader v-model="fileList0" :upload-icon="uploadInvoiceImg"  :after-read="invoice1" :max-count="1" multiple  :class="fileList0[0]?'':'imgHide'"/>
                        <img :src="uploadInvoiceImg" alt="" v-if="!fileList0[0]">
                    </div>
                    <div class="pdfBox fr">
                        <van-uploader v-model="fileList1" :upload-icon="uploadInvoiceImg" accept="application/pdf" :after-read="invoice2" :max-count="1" multiple :class="fileList1[0]?'':'imgHide'"/>
                        <img :src="uploadPdf" alt="" v-if="!fileList1[0]">
                        <p>点击上传pdf格式的发票</p>
                    </div>
                </div>
                <div class="uploadImg " v-if="invoice_type==2">
                    <div class="uploadBox ">
                        <van-uploader v-model="fileList2" :upload-icon="uploadInvoiceImg"  :after-read="invoice3" :max-count="1" multiple :class="fileList2[0]?'':'imgHide'"/>
                        <img :src="uploadInvoiceImg" alt="" v-if="!fileList2[0]">
                    </div>
                </div>
            </div>
        </div> -->
        <div class="sixTypes">
            <div class="title">
                请选择申请类型
            </div>
          <div class="onetype">
            <div class="wrap" :class="onetype?'wrapHeight':''" style="padding: 0">
              <div class="inputWrap" style="padding: 20px 0 0 0;">
                <div class="label">申请金额</div>
                <div class="inputBoxMoney">
                  <span>￥</span><input type="number" v-model="typeFileList1.amount" @input="addMoney"
                                       placeholder="请填写金额">
                </div>
              </div>
            </div>
          </div>
            <div v-for="(val,inx) in listShow" :key="inx">
                <!--推广宣传-->
                <div class="onetype" v-if="val.type==1">
                    <div class="Overlay" v-if="typeFileList1.status==1"></div>
                    <div class="wrap" :class="onetype?'wrapHeight':''">
                        <div class="openImg " :class="onetype?'retractActive':'openactive'" @click="opentype(1)">
                            <img :src="openImg" alt="">
                        </div>
                        <div class="typeTitle">
                            <span>申请类型</span> <span class="name">推广宣传</span>
                        </div>
                        <div class="deleteBox clearfix" v-if="typeFileList1.status!=1">
                            <div class="text fl" v-if="typeFileList1.status==2">驳回原因：{{typeFileList1.reject_reason}}
                            </div>
                            <div class="delBtn fr" @click="del1">删除</div>
                        </div>
                        <div class="uploadBox">
                            <van-uploader v-model="preview1" upload-icon="plus" accept="image/*" @delete="delete1"
                                          :after-read="onetypeUpload" :max-count="20" multiple
                                          :class="typeFileList1[0]?'':'imgHide'"/>
                        </div>
                        <p class="desc">最多上传20张照片</p>
                        <div class="remarks"><p>备注:</p><textarea v-model="typeFileList1.remark"/></div>
                        <div class="inputWrap">
                            <div class="label">申请金额</div>
                            <div class="inputBoxMoney">
                                <span>￥</span><input type="number" v-model="typeFileList1.amount" @input="addMoney"
                                                     placeholder="请填写推广宣传金额">
                            </div>
                        </div>
                    </div>
                </div>
                <!--社群管理-->
                <div class="twoType" v-if="val.type==2">
                    <div class="wrap" :class="twotype?'wrapHeight':''">
                        <div class="openImg" :class="twotype?'retractActive':'openactive'" @click="opentype(2)">
                            <img :src="openImg" alt="">
                        </div>
                        <div class="typeTitle Overlay">
                            <div><span>申请类型</span> <span class="name">社群管理</span></div>
                            <div><span>管理社群数</span><input type="number" v-model="twoTypeNumber" @blur="twoTypeNum">
                            </div>
                        </div>
                        <ul v-if="twoTypeNumber">
                            <li v-for="(v,i) in typeFileList2.data.group_detail" :key="i"
                                :class="v.status==1?'Overlay':''">
                                <div class="deleteBox clearfix" v-if="v.status!=1">
                                    <div class="text fl" v-if="v.status==2">驳回原因：{{v.reject_reason}}</div>
                                    <div class="delBtn fr" @click="del2(i)">删除</div>
                                </div>
                                <div class="name">管理群{{i+1}}</div>
                                <div class="inputbox">
                                    <span class="text">群名称</span><input type="text" placeholder="请填写群名称"
                                                                        v-model="v.name">
                                </div>
                                <div class="inputbox">
                                    <span class="text1">群成员数量</span><input type="number" required placeholder="请填写群成员数量"
                                                                           v-model="v.num">
                                </div>
                                <div class="inputbox">
                                    <span class="text1">群管理角色</span><input type="text" readonly placeholder="请填写管理角色"
                                                                           v-model="v.role">
                                </div>
                                <div v-if="v.role">
                                    <van-radio-group v-model="v.role" direction="horizontal" class="radioWrap">
                                        <van-radio name="群主">群主
                                            <template #icon="props">
                                                <span :class="props.checked ? 'inactiveIcon' : 'activeIcon'"/>
                                            </template>
                                        </van-radio>
                                        <van-radio name="群管理员">群管理员
                                            <template #icon="props">
                                                <span :class="props.checked ? 'inactiveIcon' : 'activeIcon'"/>
                                            </template>
                                        </van-radio>
                                        <van-radio name="讲师">讲师
                                            <template #icon="props">
                                                <span :class="props.checked ? 'inactiveIcon' : 'activeIcon'"/>
                                            </template>
                                        </van-radio>
                                    </van-radio-group>
                                </div>

                                <div class="uploadBox">
                                    <van-uploader v-model="preview2[i]" accept="image/*" upload-icon="plus"
                                                  :before-delete="delete2(i)" :after-read="afterRead2(i)" :max-count="9"
                                                  multiple/>
                                </div>
                                <div class="desc">最多上传9张照片</div>
                                <div class="remarks"><p>备注:</p><textarea v-model="v.remark"/></div>
                                <div class="inputWrap">
                                    <div class="label">申请金额</div>
                                    <div class="inputBoxMoney">
                                        <span>￥</span><input type="number" v-model="v.amount" @input="addMoney"
                                                             placeholder="请填写管理群金额">
                                    </div>
                                </div>
                            </li>

                        </ul>

                    </div>
                </div>
                <!--自媒体推广-->
                <div class="twoType" v-if="val.type==7">
                    <div class="wrap" :class="twotype?'wrapHeight':''">
                        <div class="openImg" :class="twotype?'retractActive':'openactive'" @click="opentype(2)">
                            <img :src="openImg" alt="">
                        </div>
                        <div class="typeTitle Overlay">
                            <div><span>申请类型</span> <span class="name">自媒体推广</span></div>
                            <div><span>直播场次数</span><input type="number" v-model="sevenTypeNumber" @blur="sevenTypeNum">
                            </div>
                        </div>
                        <ul v-if="sevenTypeNumber">
                            <li v-for="(v,i) in typeFileList7.data.group_detail" :key="i"
                                :class="v.status==1?'Overlay':''">
                                <div class="deleteBox clearfix" v-if="v.status!=1">
                                    <div class="text fl" v-if="v.status==2">驳回原因：{{v.reject_reason}}</div>
                                    <div class="delBtn fr" @click="del7(i)">删除</div>
                                </div>
                                <div class="name">管理场{{i+1}}</div>
                                <div class="inputbox">
                                    <span class="text1">直播间名称</span><input type="text" placeholder="请填写直播间名称" v-model="v.zb_name">
                                </div>
                                <div class="inputbox" style="display: flex;">
                                    <span class="text1">直播开始时间</span><input type="text" readonly :value="v.start_time" @click="zbTimeMode(i,1)">
                                </div>
                                <div class="inputbox" style="display: flex;">
                                    <span class="text1">直播结束时间</span><input type="text" readonly :value="v.end_time" @click="zbTimeMode(i,2)">
                                </div>
                                <div class="inputbox">
                                    <span class="text1">观看人数</span><input type="text" readonly placeholder="请填写观看人数" v-model="v.num">
                                </div>


                                <div class="uploadBox">
                                    <van-uploader v-model="preview8" upload-icon="plus" accept="image/*" @delete="delete8(i)"
                                                  :after-read="afterRead8(i)" :max-count="9" multiple
                                                  :class="typeFileList7[0]?'':'imgHide'"/>
                                </div>
                                <div class="desc">最多上传9张照片</div>
                                <div class="remarks"><p>备注:</p><textarea v-model="v.remark"/></div>
                                <div class="inputWrap">
                                    <div class="label">申请金额</div>
                                    <div class="inputBoxMoney">
                                        <span>￥</span><input type="number" v-model="v.amount" @input="addMoney"
                                                             placeholder="请填写管理群金额">
                                    </div>
                                </div>
                            </li>

                        </ul>

                    </div>
                </div>
                <!--宣传物料-->
                <div class="onetype" v-if="val.type==4">
                    <div class="Overlay" v-if="typeFileList5.status==1"></div>
                    <div class="wrap" :class="fivetype?'wrapHeight':''">
                        <div class="openImg " :class="fivetype?'retractActive':'openactive'" @click="opentype(5)">
                            <img :src="openImg" alt="">
                        </div>
                        <div class="typeTitle">
                            <span>申请类型</span> <span class="name">宣传物料</span>
                        </div>
                        <div class="deleteBox clearfix" v-if="typeFileList5.status!=1">
                            <div class="text fl" v-if="typeFileList5.status==2">驳回原因：{{typeFileList5.reject_reason}}
                            </div>
                            <div class="delBtn fr" @click="del5">删除</div>
                        </div>
                        <div class="uploadBox">
                            <van-uploader v-model="preview5" upload-icon="plus" accept="image/*" @delete="delete5"
                                          :after-read="afterRead5" :max-count="9"/>
                        </div>
                        <p class="desc">最多上传9张照片</p>
                        <div class="remarks"><p>备注:</p><textarea v-model="typeFileList5.remark"/></div>
                        <div class="inputWrap">
                            <div class="label">申请金额</div>
                            <div class="inputBoxMoney">
                                <span>￥</span><input type="number" v-model="typeFileList5.amount" @input="addMoney"
                                                     placeholder="请填写宣传物料金额">
                            </div>
                        </div>
                    </div>
                </div>
                <!--售后服务-->
                <div class="onetype" v-if="showSale&&val.type==5">
                    <div class="Overlay" v-if="typeFileList3.status==1"></div>
                    <div class="wrap" :class="threetype?'wrapHeight':''">
                        <div class="openImg " :class="threetype?'retractActive':'openactive'" @click="opentype(3)">
                            <img :src="openImg" alt="">
                        </div>
                        <div class="typeTitle">
                            <span>申请类型</span> <span class="name">售后服务</span>
                        </div>
                        <div class="deleteBox clearfix" v-if="typeFileList3.status!=1">
                            <div class="text fl" v-if="typeFileList3.status==2">驳回原因：{{typeFileList3.reject_reason}}
                            </div>
                            <div class="delBtn fr" @click="del3">删除</div>
                        </div>
                        <div class="uploadBox">
                            <van-uploader v-model="preview3" upload-icon="plus" accept="image/*" @delete="delete3"
                                          :after-read="afterRead3" :max-count="9" multiple
                                          :class="typeFileList3[0]?'':'imgHide'"/>
                        </div>
                        <p class="desc">最多上传9张照片</p>
                        <div class="remarks"><p>备注:</p><textarea v-model="typeFileList3.remark"/></div>
                        <div class="inputWrap">
                            <div class="label">申请金额</div>
                            <div class="inputBoxMoney bornone">
                                <span>￥</span><input type="number" v-model="typeFileList3.amount" readonly>
                            </div>
                        </div>
                    </div>
                </div>
                <!--售前服务-->
                <div class="onetype" v-if="showSale&&val.type==3">
                    <div class="Overlay" v-if="typeFileList4.status==1"></div>
                    <div class="wrap" :class="fourtype?'wrapHeight':''">
                        <div class="openImg " :class="fourtype?'retractActive':'openactive'" @click="opentype(4)">
                            <img :src="openImg" alt="">
                        </div>
                        <div class="typeTitle">
                            <span>申请类型</span> <span class="name">售前服务</span>
                        </div>
                        <div class="deleteBox clearfix" v-if="typeFileList4.status!=1">
                            <div class="text fl" v-if="typeFileList4.status==2">驳回原因：{{typeFileList4.reject_reason}}
                            </div>
                            <div class="delBtn fr" @click="del4">删除</div>
                        </div>
                        <div class="uploadBox">
                            <van-uploader v-model="preview4" upload-icon="plus" accept="image/*" @delete="delete4"
                                          :after-read="afterRead4" :max-count="9" multiple
                                          :class="typeFileList4[0]?'':'imgHide'"/>
                        </div>
                        <p class="desc">最多上传9张照片</p>
                        <div class="remarks"><p>备注:</p><textarea v-model="typeFileList4.remark"/></div>
                        <div class="inputWrap">
                            <div class="label">申请金额</div>
                            <div class="inputBoxMoney">
                                <span>￥</span><input type="number" v-model="typeFileList4.amount" @input="addMoney"
                                                     placeholder="请填写售前服务金额">
                            </div>
                        </div>
                    </div>
                </div>
                <!--会议服务-->
                <div class="threeType" v-if="val.type==6">
                    <div class="wrap" :class="sixtype?'wrapHeight':''">
                        <div class="openImg " :class="sixtype?'retractActive':'openactive'" @click="opentype(6)">
                            <img :src="openImg" alt="">
                        </div>
                        <div class="typeTitle Overlay">
                            <div><span>申请类型</span> <span class="name">会议服务</span></div>
                            <van-radio-group v-model="typeFileList6.meet_type" direction="horizontal" class="radioWrap">
                                <van-radio name="conference">会务
                                    <template #icon="props">
                                        <span :class="props.checked ? 'inactiveIcon' : 'activeIcon'"/>
                                    </template>
                                </van-radio>
                                <van-radio name="host">主持
                                    <template #icon="props">
                                        <span :class="props.checked ? 'inactiveIcon' : 'activeIcon'"/>
                                    </template>
                                </van-radio>
                                <van-radio name="lecturer">讲师
                                    <template #icon="props">
                                        <span :class="props.checked ? 'inactiveIcon' : 'activeIcon'"/>
                                    </template>
                                </van-radio>
                                <van-radio name="master">主办方
                                    <template #icon="props">
                                        <span :class="props.checked ? 'inactiveIcon' : 'activeIcon'"/>
                                    </template>
                                </van-radio>
                            </van-radio-group>
                            <div><span>场次数量</span><input type="number" v-model="sixTypeNumber" @blur="twoTypeNum6">
                            </div>
                        </div>
                        <ul v-if="sixTypeNumber">
                            <li v-for="(v,i) in typeFileList6.data.group_detail" :key="i"
                                :class="v.status==1?'Overlay':''">
                                <div class="deleteBox clearfix" v-if="v.status!=1">
                                    <div class="text fl" v-if="v.status==2">驳回原因：{{v.reject_reason}}</div>
                                    <div class="delBtn fr" @click="del6(i)">删除</div>
                                </div>
                                <div class="name">场{{i+1}}</div>
                                <div class="inputbox">
                                    <span class="text">会议名称</span><input type="text" placeholder="请填写会议名称"
                                                                         v-model="v.name">
                                </div>
                                <div class="inputbox">
                                    <span class="text">会议人数</span><input type="number" placeholder="请填写会议人数"
                                                                         v-model="v.num">
                                </div>
                                <div class="inputbox">
                                    <span class="text">会议地点</span><input type="text" placeholder="请填写会议地点"
                                                                         v-model="v.address">
                                </div>
                                <div class="inputbox" v-if="typeFileList6.meet_type=='lecturer'">
                                    <span class="text">讲课内容</span><input type="text" placeholder="请填写讲课内容"
                                                                         v-model="v.content">
                                </div>
                                <div class="uploadBox">
                                    <van-uploader v-model="preview6[i]" upload-icon="plus" accept="image/*"
                                                  :before-delete="delete6(i)" :after-read="afterRead6(i)" :max-count="9"
                                                  multiple/>
                                </div>
                                <div class="uploadBox" v-if="typeFileList6.meet_type=='master'">
                                    <p>签到表</p>
                                    <van-uploader v-model="preview7[i]" accept="image/*" upload-icon="plus"
                                                  :before-delete="delete7(i)" :after-read="afterRead7(i)" :max-count="1"
                                                  multiple/>
                                </div>
                                <div class="desc">最多上传9张照片 <span
                                        v-if="typeFileList6.meet_type=='master'">(主办方需要上传签到表)</span> <span
                                        v-if="typeFileList6.meet_type=='lecturer'">（课件内容请发送到公司邮箱huaguduo2021@163.com）</span>
                                </div>
                                <div class="remarks"><p>备注:</p><textarea v-model="v.remark"/></div>
                                <div class="inputWrap">
                                    <div class="label">申请金额</div>
                                    <div class="inputBoxMoney">
                                        <span>￥</span><input type="number" v-model="v.amount" @input="addMoney"
                                                             placeholder="请填写会议金额">
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>


            </div>
        </div>
        <div class="applyPrice" v-if="co_mode==1">
            <div class="cardPrice">
                <div class="applyBank">
                    <div class="label">申请到</div>
                    <div class="r" @click="bankShowPopup">
                        <span>{{bankInfo}}</span>
                        <van-icon name="arrow" color="rgba(119,118,118,1)"/>
                    </div>
                </div>
            </div>
        </div>

        <div style="height: 1.2rem"></div>
        <div class="fixedBot">
            <div class="wrap">
                <div class="left">申请金额：<span>￥{{number}}</span></div>
                <van-button type="primary" size="small" color="#F15223" round @click="submitApply">确认提交</van-button>
            </div>
        </div>
        <van-popup
                v-model="bankShow"
                closeable
                close-icon-position="top-left"
                position="bottom"
                :style="{ height: '50%' }"
        >
            <ul class="bankList">
                <li v-for="(v,i) in bankList" :key="i"
                    @click="selectBank(v.account_bank,v.account_num,v.id,v.account_num_real)">
                    <span>{{v.account_bank}}( {{v.account_num}} )</span>
                    <van-icon name="success" color="rgba(241,82,35,1)" v-if="v.id==bankId"/>
                </li>
            </ul>
        </van-popup>

        <van-dialog v-model="submitPopupShow" title=" " closeOnClickOverlay :showConfirmButton="false"
                    :showCancelButton="false" width="60%" show-cancel-button>
            <div class="submitPopupBox">
                <div class="title">
                    <p class="name">申请金额</p>
                    <p class="price">￥{{number}}</p>
                </div>
                <ul>
                    <li>
                        <span>申请金额</span> <span>￥{{number}}</span>
                    </li>
                    <li>
                        <span>预计服务费（7%）</span> <span>￥{{ratePrice}}</span>
                    </li>
                    <li>
                        <span>预计实收金额</span> <span>￥{{realPrice}}</span>
                    </li>
                </ul>
                <div class="tip">预计1-3个工作日审核通过</div>
                <div class="submitBtn" @click="submitBtn">
                    确认提交
                </div>
            </div>
        </van-dialog>


        <van-action-sheet v-model="zbMode" title="">
            <van-picker title="选择年月日" :columns="currentTime"   @confirm="onDateConfirm" @change="onDateChange" @cancel="onDateCancel" :show-toolbar="true"/>

        </van-action-sheet>
    </div>
</template>

<script>
    import api from "@/api/api"

    export default {
        data() {
            return {
                listShow: "",
                noBank: null,
                openid: "",
                login: localStorage.getItem("login_token"),
                applyId: "",
                delId: [],
                realPrice: 0,
                ratePrice: 0,
                price: 0,
                user: "",
                bankInfo: "",
                bankId: 0,
                co_mode: 1,/* 合作方式 */
                bankList: [],
                submitPopupShow: false,/* 提交弹框 */
                number: 0,
                originNumberType: 0,
                originNumber: 0,/* 金额 */
                invoiceImg: require("@/img/apply/icon_xuanzhong.png"),
                invoiceImgDefault: require("@/img/apply/icon_weixuanzhong.png"),
                uploadInvoiceImg: require("@/img/apply/shangchuanzhizhifapiao.png"),
                uploadPdf: require("@/img/apply/shangchuanpdf.png"),
                openImg: require("@/img/apply/zhankai.png"),
                showSale: true,
                bankShow: false,/* 银行弹出框 */
                twoTypeNumber: 0,
                twoTypeindex: 0,
                fiveTypeNumber: 0,
                tip: "",
                sixTypeNumber: 0,
                sevenTypeNumber: 0,
                sevenTypeindex: 0,
                invoice_type: 1,
                onetype: false,
                twotype: false,
                threetype: false,
                fourtype: false,
                fivetype: false,
                sixtype: false,
                seventype: false,
                fileList0: [],
                fileList1: [],
                fileList2: [],
                preview1: [],
                preview2: [],
                preview3: [],
                preview4: [],
                preview5: [],
                preview6: [],
                preview7: [],
                preview8: [],
                type6: ["lecturer", "host", "conference"],
                typeFileList1: {
                    type: 1,
                    remark: "",
                    amount: "",
                    data: {
                        imgs: []
                    }
                },
                typeFileList3: {
                    type: 5,
                    remark: "",
                    amount: "",
                    data: {
                        imgs: []
                    }
                },
                typeFileList4: {
                    type: 3,
                    remark: "",
                    amount: "",
                    data: {
                        imgs: []
                    }
                },
                typeFileList2: {
                    type: 2,
                    remark: "",
                    data: {
                        group_num: 0,
                        group_detail: []
                    }
                },
                typeFileList5: {
                    type: 4,
                    amount: "",
                    remark: "",
                    data: {
                        imgs: []
                    }
                },
                typeFileList6: {
                    type: 6,
                    remark: "",
                    meet_type: "conference",
                    data: {
                        "group_num": 0,
                        "group_detail": []
                    }
                },
                typeFileList7: {
                    type: 7,//自媒体推广
                    data: {
                        "group_num": 0,
                        "group_detail": []
                    }
                },
                data: [],
                invoice_file: "", /* pdf文件 */
                invoice_img: "", /* 发票图片 */
                zbMode:false,
                currentTime:'',
                typeFileList7Index:0,//选择typeFileList7Index的下标
                dateTime:1,//1==开始使劲按  2==结束时间
                dateArr:[],
            }
        },
        methods: {
            zbTimeMode(i,type){
                console.log(i)
                this.dateTimes()
                this.zbMode=true;
                this.typeFileList7Index=i;
                this.dateTime=type;


            },
            //选择日期确认
            onDateConfirm(index){
                console.log(index)
                var i=this.typeFileList7Index;
                var type=this.dateTime;
                var year=index[0].substring(0,index[0].indexOf('年'));
                var month=index[1].substring(0,index[1].indexOf('月'));
                var day=index[2].substring(0,index[2].indexOf('日'));

                var time=year+'/'+month+'/'+day+' '+index[3]+':'+index[4]+':'+index[5];
                if(type==1){
                    this.typeFileList7.data.group_detail[i].start_time=time
                }else{
                    this.typeFileList7.data.group_detail[i].end_time=time
                }
                this.zbMode=false;
                for (var k=0;k<this.currentTime.length;k++) {
                    this.currentTime[k].defaultIndex=0
                }

            },
            onDateChange(value,index){
                console.log(value,index)
                var dateYear=index[0];
                var yearNum=dateYear.substring(0,dateYear.indexOf('年'))
                var dateMonth=index[1];
                var monthNum=dateMonth.substring(0,dateMonth.indexOf('月'))
                var  dayNum=new Date(yearNum,monthNum,0).getDate();
                console.log(yearNum,monthNum,dayNum);
                var day=[];
                for(var j=0;j<dayNum;j++){
                    day=day.concat((j+1)+'日')
                }
                this.currentTime[2].values=day;





            },
            //选择日期取消
            onDateCancel(){
                this.zbMode=false;
            },
            // 删除
            del1() {
                this.delId.push(this.typeFileList1.id)
                this.typeFileList1.data.imgs = [];
                for (var i = 0; i < this.listShow.length; i++) {
                    if (this.listShow[i].type == 1) {
                        this.listShow.splice(i, 1)
                    }
                }
                this.typeFileList1.amount = 0;
                this.preview1 = []
                this.addMoney()
            },
            del2(i) {
                this.delId.push(this.typeFileList2.data.group_detail[i].id)
                this.typeFileList2.data.group_detail.splice(i, 1);
                this.typeFileList2.data.group_num--;
                this.preview2.splice(i, 1)
                this.twoTypeNumber--;
                this.addMoney()
            },
            del7(i) {
                this.delId.push(this.typeFileList7.data.group_detail[i].id)
                this.typeFileList7.data.group_detail.splice(i, 1);
                this.typeFileList7.data.group_num--;
                this.preview8.splice(i, 1)
                this.sevenTypeNumber--;
                this.addMoney()
            },
            del3() {
                this.delId.push(this.typeFileList3.id)
                this.typeFileList3.data.imgs = [];
                this.typeFileList3.amount = 0;
                for (var i = 0; i < this.listShow.length; i++) {
                    if (this.listShow[i].type == 5) {
                        this.listShow.splice(i, 1)
                    }
                }
                this.preview3 = []
                this.addMoney()
            },
            del4() {
                this.delId.push(this.typeFileList4.id)
                this.typeFileList4.data.imgs = [];
                this.typeFileList4.amount = 0;
                for (var i = 0; i < this.listShow.length; i++) {
                    if (this.listShow[i].type == 3) {
                        this.listShow.splice(i, 1)
                    }
                }
                this.preview4 = []
                this.addMoney()
            },
            del5() {
                this.delId.push(this.typeFileList5.id)
                this.typeFileList5.data.imgs = [];
                this.typeFileList5.amount = 0;
                for (var i = 0; i < this.listShow.length; i++) {
                    if (this.listShow[i].type == 4) {
                        this.listShow.splice(i, 1)
                    }
                }
                this.preview5 = []
                this.addMoney()
            },
            del6(i) {
                this.delId.push(this.typeFileList6.data.group_detail[i].id)
                this.typeFileList6.data.group_detail.splice(i, 1);
                this.typeFileList6.data.group_num--;
                this.sixTypeNumber--;
                this.preview6.splice(i, 1)
                this.addMoney()
            },
            // 金额计算
            async addMoney() {
                var money2 = 0, money6 = 0, total, money3 = 0;
                total = money2 + money6 + money3 + this.typeFileList1.amount * 1 + this.typeFileList3.amount * 1 + this.typeFileList4.amount * 1 + this.typeFileList5.amount * 1
                this.number = Math.abs(total.toFixed(0))
            },
            // 确认提交
            submitBtn() {
                var data = JSON.stringify(this.data), delId = this.delId.toString();
                console.log(data)
                console.log(delId)
                this.$toast.loading({
                    message: '加载中...',
                    forbidClick: true,
                    duration: 0
                });
                api.apply.modifyApplyNew(this.openid, this.login, this.number,this.bankId, this.applyId).then(res => {
                    console.log(res)
                    this.$toast.clear()
                    if (res.data.code == 0) {
                        this.$toast(res.data.msg)
                        setTimeout(function () {
                            window.history.back()
                        },1500)
                        this.submitPopupShow = false
                        // this.$router.push('/applyRecord')

                    } else {
                        this.$toast(res.data.msg)
                    }
                }).catch(req => {
                    console.log(req)
                    this.$toast.clear();
                    this.$toast('请求超时');
                    setTimeout(function () {
                        window.history.back()
                    },1500)
                    this.submitPopupShow = false;

                })
            },
            selectBank(v1, v2, v3, rel) {
                this.nobank = rel
                this.bankId = v3
                this.bankInfo = v1 + "(" + v2 + ")"
                this.bankShow = false
            },
            bankShowPopup() {
                if (this.co_mode != 1) {
                    return
                }
                this.bankShow = true
            },
            // 上传发票电子
            invoice1(file) {
                let data = new FormData();
                file.status = 'uploading';
                file.message = '上传中...';
                data.append('file', file.file);
                api.my.uploadIMg(data).then(res => {
                    console.log(res)
                    if (res.data.uploaded) {
                        this.invoice_img = res.data.url
                        file.status = '';
                        file.message = '';
                    } else {
                        file.status = 'failed';
                        file.message = '上传失败';
                    }
                }).catch(req => {
                    console.log(req)
                    this.$toast(req.data.msg)
                })
            },
            // 上传pdf格式
            invoice2(file) {
                let data = new FormData();
                file.status = 'uploading';
                file.message = '上传中...';
                data.append('file', file.file);
                api.my.uploadIMg(data).then(res => {
                    if (res.data.uploaded) {
                        this.invoice_file = res.data.url
                        file.status = '';
                        file.message = '';
                    } else {
                        file.status = 'failed';
                        file.message = '上传失败';
                    }
                }).catch(req => {

                    this.$toast(req.data.msg)
                })
            },
            // 上传纸质发票
            invoice3(file) {
                let data = new FormData();
                file.status = 'uploading';
                file.message = '上传中...';
                data.append('file', file.file);
                api.my.uploadIMg(data).then(res => {
                    console.log(res)
                    if (res.data.uploaded) {
                        this.invoice_img = res.data.url
                        file.status = '';
                        file.message = '';
                    } else {
                        file.status = 'failed';
                        file.message = '上传失败';
                    }
                }).catch(req => {
                    this.$toast(req.data.msg)
                })
            },
            // 检测金额
            originVal(e) {
                var val = e.target.value
                this.originNumber = val
                if (val == 0) {
                    this.originNumberType = 0
                } else if (val > 0 && val < 500) {
                    this.originNumberType = 1
                } else if (val >= 500 && val < 1000) {
                    this.originNumberType = 2
                } else if (val >= 1000 && val < 6000) {
                    this.originNumberType = 3
                } else if (val >= 6000 && val < 10000) {
                    this.originNumberType = 4
                }
            },
            inspect() {
                var val = this.number;
                var istype = 0;
                if (val < 500) {
                    istype = 1
                } else if (val >= 500 && val < 1000) {
                    istype = 2
                } else if (val >= 1000 && val < 6000) {
                    istype = 3
                } else if (val >= 6000 && val < 10000) {
                    istype = 4
                }
                if (istype == this.originNumberType || this.originNumberType == 0) {
                    api.apply.applyPrice(this.openid, this.login, this.number, 1).then(res => {
                        console.log(res)
                        if (res.data.code == 0) {
                            this.$toast(res.data.msg)
                            this.ishow = true
                            this.tip = ""
                        } else {
                            this.tip = res.data.msg
                            this.ishow = false
                            this.$toast(res.data.msg)
                        }
                    })
                } else {
                    this.$dialog.confirm({
                        title: '修改金额提示',
                        message: '修改金额之后,之前填写的内容将全部清空,您将需要重新填写,您确定要修改吗?',
                    })
                        .then(() => {
                            this.reset();
                            api.apply.applyPrice(this.openid, this.login, this.number, 1).then(res => {
                                console.log(res)
                                if (res.data.code == 0) {
                                    this.$toast(res.data.msg)
                                    this.ishow = true
                                    this.tip = ""
                                } else {
                                    this.tip = res.data.msg
                                    this.ishow = false
                                    this.$toast(res.data.msg)
                                }
                            })
                        })
                        .catch(() => {
                            this.number = this.originNumber
                        });
                }


            },
            // 社群管理次数
            twoTypeNum() {
                this.typeFileList2.data.group_detail = [];
                this.preview7 = []
                for (var i = 0; i < this.twoTypeNumber; i++) {
                    this.$set(this.typeFileList2.data.group_detail, i, {name: "", num: "", imgs: [], role: "群主"})
                }
            },
            //自媒体直播次数
            sevenTypeNum() {
                this.typeFileList7.data.group_detail = [];
                this.preview8 = []
                for (var i = 0; i < this.sevenTypeNumber; i++) {
                    this.$set(this.typeFileList7.data.group_detail, i, {
                        name: "管理场" + (i + 1),
                        zb_name: "",
                        zb_time: "",
                        start_time: "",
                        end_time: "",
                        num: '',
                        imgs: [],
                        amount: "",
                        remark: "",
                    })
                }

            },
            // 讲课次数
            twoTypeNum6() {
                this.typeFileList6.data.group_detail = [];
                this.preview6 = []
                for (var j = 0; j < this.sixTypeNumber; j++) {
                    this.typeFileList6.data.group_detail[j] = {name: "", num: "", imgs: [], sign_table: "", content: ""}
                }
            },
            opentype(i) {
                switch (i) {
                    case 1:
                        this.onetype = !this.onetype;
                        break;
                    case 2:
                        this.twotype = !this.twotype;
                        break;
                    case 3:
                        this.threetype = !this.threetype;
                        break;
                    case 4:
                        this.fourtype = !this.fourtype;
                        break;
                    case 5:
                        this.fivetype = !this.fivetype;
                        break;
                    case 6:
                        this.sixtype = !this.sixtype;
                        break;
                    case 7:
                        this.seventype = !this.seventype;
                        break;
                }
            },
            modifyType(i) {
                this.invoice_type = i
            },
            onClickLeft() {
                this.$router.go(-1)
            },
            // afterRead
            onetypeUpload(file) {
                file.status = 'uploading';
                file.message = '上传中...';
                if (file instanceof Array) {
                    file.forEach((v) => {
                        let data = new FormData();
                        data.append('file', v.file);
                        api.my.uploadIMg(data).then(res => {
                            console.log(res)
                            if (res.data.uploaded) {
                                this.typeFileList1.data.imgs.push(res.data.url)
                                file.status = '';
                                file.message = '';
                            } else {
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req => {
                            console.log(req)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    })
                } else {
                    let data = new FormData();
                    data.append('file', file.file);
                    api.my.uploadIMg(data).then(res => {
                        console.log(res)
                        if (res.data.uploaded) {
                            this.typeFileList1.data.imgs.push(res.data.url)
                            file.status = '';
                            file.message = '';
                        } else {
                            file.status = 'failed';
                            file.message = '上传失败';
                        }
                    }).catch(req => {
                        console.log(req)
                        file.status = 'failed';
                        file.message = '上传失败';
                    })
                }
            },
            delete1(file, details) {
                this.typeFileList1.data.imgs.splice(details.index, 1)
                console.log(file, this.typeFileList1)
            },
            afterRead2(i) {
                return file => {
                    if (file instanceof Array) {
                        file.forEach((v) => {
                            let data = new FormData(), that = this;
                            file.status = 'uploading';
                            file.message = '上传中...';
                            data.append('file', v.file);
                            api.my.uploadIMg(data).then(res => {
                                console.log(res)
                                if (res.data.uploaded) {
                                    that.typeFileList2.data.group_detail[i].imgs.push(res.data.url)
                                    file.status = '';
                                    file.message = '';
                                } else {
                                    file.status = 'failed';
                                    file.message = '上传失败';
                                }
                            }).catch(req => {
                                console.log(req)
                                this.$toast(req.data.msg)
                                file.status = 'failed';
                                file.message = '上传失败';
                            })
                        })
                    } else {
                        let data = new FormData(), that = this;
                        file.status = 'uploading';
                        file.message = '上传中...';
                        data.append('file', file.file);
                        api.my.uploadIMg(data).then(res => {
                            console.log(res)
                            if (res.data.uploaded) {
                                that.typeFileList2.data.group_detail[i].imgs.push(res.data.url)
                                file.status = '';
                                file.message = '';
                            } else {
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req => {
                            console.log(req)
                            this.$toast(req.data.msg)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    }
                }
            },
            delete2(i) {
                return (file, details) => {
                    console.log(file,)
                    this.typeFileList2.data.group_detail[i].imgs.splice(details.index, 1)
                    return true
                }
            },
            afterRead3(file) {
                if (file instanceof Array) {
                    file.forEach((v) => {
                        let data = new FormData();
                        file.status = 'uploading';
                        file.message = '上传中...';
                        data.append('file', v.file);
                        api.my.uploadIMg(data).then(res => {
                            console.log(res)
                            if (res.data.uploaded) {
                                this.typeFileList3.data.imgs.push(res.data.url)
                                this.typeFileList3.amount = 5000
                                this.addMoney()
                                file.status = '';
                                file.message = '';
                            } else {
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req => {
                            console.log(req)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    })
                } else {
                    let data = new FormData();
                    file.status = 'uploading';
                    file.message = '上传中...';
                    data.append('file', file.file);
                    api.my.uploadIMg(data).then(res => {
                        console.log(res)
                        if (res.data.uploaded) {
                            this.typeFileList3.data.imgs.push(res.data.url)
                            this.typeFileList3.amount = 5000
                            this.addMoney()
                            file.status = '';
                            file.message = '';
                        } else {
                            file.status = 'failed';
                            file.message = '上传失败';
                        }
                    }).catch(req => {
                        console.log(req)
                        file.status = 'failed';
                        file.message = '上传失败';
                    })
                }
            },
            delete3(file, details) {
                this.typeFileList3.data.imgs.splice(details.index, 1)
                console.log(file)

            },
            afterRead4(file) {
                if (file instanceof Array) {
                    file.forEach((v) => {
                        let data = new FormData();
                        file.status = 'uploading';
                        file.message = '上传中...';
                        data.append('file', v.file);
                        api.my.uploadIMg(data).then(res => {
                            console.log(res)
                            if (res.data.uploaded) {
                                this.typeFileList4.data.imgs.push(res.data.url)
                                file.status = '';
                                file.message = '';
                            } else {
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req => {
                            console.log(req)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    })
                } else {
                    let data = new FormData();
                    file.status = 'uploading';
                    file.message = '上传中...';
                    data.append('file', file.file);
                    api.my.uploadIMg(data).then(res => {
                        console.log(res)
                        if (res.data.uploaded) {
                            this.typeFileList4.data.imgs.push(res.data.url)
                            file.status = '';
                            file.message = '';
                        } else {
                            file.status = 'failed';
                            file.message = '上传失败';
                        }
                    }).catch(req => {
                        console.log(req)
                        file.status = 'failed';
                        file.message = '上传失败';
                    })
                }
            },
            delete4(file, details) {
                this.typeFileList4.data.imgs.splice(details.index, 1)
                console.log(file,)
            },
            afterRead5(file) {
                if (file instanceof Array) {
                    file.forEach((v) => {
                        let data = new FormData();
                        file.status = 'uploading';
                        file.message = '上传中...';
                        data.append('file', v.file);
                        api.my.uploadIMg(data).then(res => {
                            console.log(res)
                            if (res.data.uploaded) {
                                this.typeFileList5.data.imgs.push(res.data.url)
                                file.status = '';
                                file.message = '';
                            } else {
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req => {
                            console.log(req)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    })
                } else {
                    let data = new FormData();
                    file.status = 'uploading';
                    file.message = '上传中...';
                    data.append('file', file.file);
                    api.my.uploadIMg(data).then(res => {
                        console.log(res)
                        if (res.data.uploaded) {
                            this.typeFileList5.data.imgs.push(res.data.url)
                            file.status = '';
                            file.message = '';
                        } else {
                            file.status = 'failed';
                            file.message = '上传失败';
                        }
                    }).catch(req => {
                        console.log(req)
                        file.status = 'failed';
                        file.message = '上传失败';
                    })
                }
            },
            delete5(file, details) {
                this.typeFileList5.data.imgs.splice(details.index, 1)
                console.log(file,)
            },
            afterRead6(i) {
                return file => {
                    if (file instanceof Array) {
                        file.forEach((v) => {
                            let data = new FormData(), that = this;
                            file.status = 'uploading';
                            file.message = '上传中...';
                            data.append('file', v.file);
                            api.my.uploadIMg(data).then(res => {
                                console.log(res)
                                if (res.data.uploaded) {
                                    that.typeFileList6.data.group_detail[i].imgs.push(res.data.url)
                                    file.status = '';
                                    file.message = '';
                                } else {
                                    file.status = 'failed';
                                    file.message = '上传失败';
                                }
                            }).catch(req => {
                                console.log(req)
                                file.status = 'failed';
                                file.message = '上传失败';
                            })
                        })
                    } else {
                        let data = new FormData(), that = this;
                        file.status = 'uploading';
                        file.message = '上传中...';
                        data.append('file', file.file);
                        api.my.uploadIMg(data).then(res => {
                            console.log(res)
                            if (res.data.uploaded) {
                                that.typeFileList6.data.group_detail[i].imgs.push(res.data.url)
                                file.status = '';
                                file.message = '';
                            } else {
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req => {
                            console.log(req)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    }
                }
            },
            delete6(i) {
                var that = this
                return (file, details) => {
                    console.log(file)
                    that.typeFileList6.data.group_detail[i].imgs.splice(details.index, 1)
                    that.preview6[i].splice(details.index, 1)
                    return true
                }
            },
            afterRead7(i) {
                return file => {
                    if (file instanceof Array) {
                        file.forEach((v) => {
                            let data = new FormData(), that = this;
                            file.status = 'uploading';
                            file.message = '上传中...';
                            data.append('file', v.file);
                            api.my.uploadIMg(data).then(res => {
                                console.log(res)
                                if (res.data.uploaded) {
                                    that.typeFileList6.data.group_detail[i].sign_table = res.data.url
                                    file.status = '';
                                    file.message = '';
                                } else {
                                    file.status = 'failed';
                                    file.message = '上传失败';
                                }
                            }).catch(req => {
                                console.log(req)
                                file.status = 'failed';
                                file.message = '上传失败';
                            })
                        })
                    } else {
                        let data = new FormData(), that = this;
                        file.status = 'uploading';
                        file.message = '上传中...';
                        data.append('file', file.file);
                        api.my.uploadIMg(data).then(res => {
                            console.log(res)
                            if (res.data.uploaded) {
                                that.typeFileList6.data.group_detail[i].sign_table = res.data.url
                                file.status = '';
                                file.message = '';
                            } else {
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req => {
                            console.log(req)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    }
                }
            },
            delete7(i) {
                return (file) => {
                    console.log(file)
                    this.typeFileList6.data.group_detail[i].sign_table = ''
                    this.preview7[i] = {}
                    return true
                }
            },
            afterRead8(i) {
                return file => {
                    if (file instanceof Array) {
                        file.forEach((v) => {
                            let data = new FormData(), that = this;
                            file.status = 'uploading';
                            file.message = '上传中...';
                            data.append('file', v.file);
                            api.my.uploadIMg(data).then(res => {
                                console.log(res)
                                if (res.data.uploaded) {
                                    that.typeFileList7.data.group_detail[i].imgs.push(res.data.url)
                                    file.status = '';
                                    file.message = '';
                                } else {
                                    file.status = 'failed';
                                    file.message = '上传失败';
                                }
                            }).catch(req => {
                                console.log(req)
                                this.$toast(req.data.msg)
                                file.status = 'failed';
                                file.message = '上传失败';
                            })
                        })
                    } else {
                        let data = new FormData(), that = this;
                        file.status = 'uploading';
                        file.message = '上传中...';
                        data.append('file', file.file);
                        api.my.uploadIMg(data).then(res => {
                            console.log(res)
                            if (res.data.uploaded) {
                                that.typeFileList7.data.group_detail[i].imgs.push(res.data.url)
                                file.status = '';
                                file.message = '';
                            } else {
                                file.status = 'failed';
                                file.message = '上传失败';
                            }
                        }).catch(req => {
                            console.log(req)
                            this.$toast(req.data.msg)
                            file.status = 'failed';
                            file.message = '上传失败';
                        })
                    }
                    console.log(this.preview8)
                    console.log(this.typeFileList7)
                }

            },
            delete8(i) {
                return (file, details) => {
                    console.log(file,)
                    this.typeFileList7.data.group_detail[i].imgs.splice(details.index, 1)
                    return true
                }
            },
            // 重置
            reset() {
                this.twoTypeNumber = 0, this.twoTypeindex = 0, this.fiveTypeNumber = 0, this.tip = "", this.sixTypeNumber = 0,
                    this.fileList0 = [], this.fileList1 = [], this.fileList2 = [], this.preview1 = [], this.preview2 = [], this.preview3 = [], this.preview4 = [], this.preview5 = [], this.preview6 = [], this.preview7 = [],
                    this.typeFileList1 = {
                        type: 1,
                        remark: "",
                        data: {
                            imgs: []
                        }
                    },
                    this.typeFileList3 = {
                        type: 5,
                        remark: "",
                        data: {
                            imgs: []
                        }
                    },
                    this.typeFileList4 = {
                        type: 3,
                        remark: "",
                        data: {
                            imgs: []
                        }
                    },
                    this.typeFileList2 = {
                        type: 2,
                        remark: "",
                        data: {
                            group_num: 0,
                            group_detail: []
                        }
                    },
                    this.typeFileList5 = {
                        type: 4,
                        remark: "",
                        data: {
                            imgs: []
                        }
                    },
                    this.typeFileList6 = {
                        type: 6,
                        remark: "",
                        data: {
                            "type": "conference",
                            "group_num": 0,
                            "group_detail": []
                        }
                    },
                    this.typeFileList7 = {
                        type: 7,//自媒体推广
                        data: {
                            "group_num": 0,
                            "group_detail": []
                        }
                    },
                    this.data = []
                this.invoice_file = "", /* pdf文件 */
                    this.invoice_img = "" /* 发票图片 */
                this.invoice_type = 1
            },
            // 提交申请
            submitApply() {
                this.data = []
                // if ((this.nobank + "").substring(0, 10) == 6228480616) {
                //     this.$toast("不支持该银行卡")
                //     return
                // }
                // if (parseInt(this.number) < 100) {
                //     this.$toast("提现金额不能小于100")
                //     return
                // }
                // this.typeFileList2.data.group_num = this.twoTypeNumber;
                // this.typeFileList6.data.group_num = this.sixTypeNumber;
                // this.typeFileList7.data.group_num = this.sevenTypeNumber;
                // if (this.twoTypeNumber > 0) {
                //
                //     for (var i = 0; i < this.typeFileList2.data.group_detail.length; i++) {
                //         if (this.typeFileList2.data.group_detail[i].name == "") {
                //             this.$toast("管理群" + parseInt(i + 1) + "的名称必填")
                //             return
                //         }
                //         if (this.typeFileList2.data.group_detail[i].num == "") {
                //             this.$toast("管理群" + parseInt(i + 1) + "的成员数量必填")
                //             return
                //         }
                //         if (this.typeFileList2.data.group_detail[i].amount == "") {
                //             this.$toast("管理群" + parseInt(i + 1) + "金额必填")
                //             return
                //         }
                //         if (this.typeFileList2.data.group_detail[i].imgs.length == 0) {
                //             this.$toast("管理群" + parseInt(i + 1) + "的图片必传")
                //             return
                //         }
                //     }
                // }
                // if (this.sevenTypeNumber > 0) {
                //
                //     for (var k = 0; k < this.sevenTypeNumber; k++) {
                //         if (this.typeFileList7.data.group_detail[k].zb_name == "") {
                //             this.$toast("管理场" + parseInt(k + 1) + "的直播间名称必填")
                //             return
                //         }
                //         if(this.typeFileList7.data.group_detail[k].start_time==''){
                //             this.$toast("请选择直播开始时间")
                //             return
                //         }else if(this.typeFileList7.data.group_detail[k].end_time==''){
                //             this.$toast("请选择直播结束时间")
                //             return
                //         }else {
                //             this.typeFileList7.data.group_detail[k].zb_time=this.typeFileList7.data.group_detail[k].start_time+'-'+this.typeFileList7.data.group_detail[k].end_time
                //         }
                //         if (this.typeFileList7.data.group_detail[k].num == "") {
                //             this.$toast("管理场" + parseInt(k + 1) + "的观看人数必填")
                //             return
                //         }
                //         if (this.typeFileList7.data.group_detail[k].amount == "") {
                //             this.$toast("管理场" + parseInt(k + 1) + "金额必填")
                //             return
                //         }
                //         if (this.typeFileList7.data.group_detail[k].imgs.length == 0) {
                //             this.$toast("管理场" + parseInt(k + 1) + "的图片必传")
                //             return
                //         }
                //     }
                // }
                // if (this.sixTypeNumber > 0) {
                //     for (var n = 0; n < this.typeFileList6.data.group_detail.length; n++) {
                //         if (this.typeFileList6.data.group_detail[n].name == "") {
                //             this.$toast("会议服务" + parseInt(n + 1) + "的名称必填")
                //             return
                //         }
                //         if (this.typeFileList6.data.group_detail[n].num == "") {
                //             this.$toast("会议服务" + parseInt(n + 1) + "的成员数量必填")
                //             return
                //         }
                //         if (this.typeFileList6.data.group_detail[n].address == "") {
                //             this.$toast("会议服务" + parseInt(n + 1) + "地点必填")
                //             return
                //         }
                //         if (this.typeFileList6.meet_type != 'lecturer') {
                //             this.$delete(this.typeFileList6.data.group_detail[n], 'content')
                //         } else {
                //             if (this.typeFileList6.data.group_detail[n].content == "") {
                //                 this.$toast("讲课内容的必传")
                //                 return
                //             }
                //         }
                //         if (this.typeFileList6.meet_type != 'master') {
                //             this.$delete(this.typeFileList6.data.group_detail[n], 'sign_table')
                //         } else {
                //             if (this.typeFileList6.data.group_detail[n].sign_table == "") {
                //                 this.$toast("签到表的图片必传")
                //                 return
                //             }
                //         }
                //         if (this.typeFileList6.data.group_detail[n].imgs.length == 0) {
                //             this.$toast("会议服务" + parseInt(n + 1) + "的图片必传")
                //             return
                //         }
                //     }
                // }
                // if (this.typeFileList1.data.imgs.length != 0) {
                //     if (this.typeFileList1.amount == "") {
                //         this.$toast("推广宣传金额不能为0")
                //         return
                //     } else {
                //         this.data.push(this.typeFileList1)
                //     }
                // } else {
                //     if (this.typeFileList1.amount != "") {
                //         this.$toast("推广宣传图片必传")
                //         return
                //     }
                // }
                // if (this.typeFileList2.data.group_detail.length != 0) {
                //     this.data.push(this.typeFileList2)
                // }
                // if (this.typeFileList3.data.imgs.length != 0) {
                //     this.data.push(this.typeFileList3)
                // } else {
                //     if (this.typeFileList3.amount > 0) {
                //         this.$toast("售后服务图片必传")
                //         return
                //     }
                // }
                // if (this.typeFileList4.data.imgs.length != 0) {
                //     if (this.typeFileList4.amount == "") {
                //         this.$toast("售前服务金额不能为0")
                //     } else {
                //         this.data.push(this.typeFileList4)
                //     }
                // } else {
                //     if (this.typeFileList4.amount != "") {
                //         this.$toast("售前服务图片必传")
                //         return
                //     }
                // }
                // if (this.typeFileList5.data.imgs.length != 0) {
                //     if (this.typeFileList5.amount == "") {
                //         this.$toast("宣传物料金额不能为0")
                //     } else {
                //         this.data.push(this.typeFileList5)
                //     }
                // } else {
                //     if (this.typeFileList5.amount != "") {
                //         this.$toast("宣传物料图片必传")
                //         return
                //     }
                // }
                // if (this.typeFileList6.data.group_detail.length != 0) {
                //     this.data.push(this.typeFileList6)
                // }
                // if (this.typeFileList7.data.group_detail.length != 0) {
                //     this.data.push(this.typeFileList7)
                // }
                if (this.co_mode == 1) {
                    this.getCost()
                } else {
                    this.submitBtn()
                }
            },
            getdata(id) {
                var that = this
                this.user = this.$store.state.WxUserInfo
                this.openid = this.$store.state.openid
                this.$toast({
                    message: '加载中...',
                    forbidClick: true,
                })
                api.apply.getApplyInfoNew(this.openid, this.login, id).then(res => {
                    this.$toast.clear()
                    if (res.data.code == 0) {
                        let applyData =[]// res.data.data.apply_data
                        that.bankId = res.data.data.apply_info.bank_id;
                        that.co_mode = res.data.data.apply_info.mode;
                        that.data = res.data.data.apply_info;
                        that.number = parseInt(res.data.data.apply_info.apply_money)
                      that.typeFileList1.amount = parseInt(res.data.data.apply_info.apply_money)
                        that.price = res.data.data.month_limit
                        for(var jq=0;jq<applyData.length;jq++){
                            if(applyData[jq].type==7){
                                for(var k=0;k<applyData[jq].data.group_detail.length;k++){
                                    var zb_time=applyData[jq].data.group_detail[k].zb_time.split('-');
                                    applyData[jq].data.group_detail[k].start_time=zb_time[0];
                                    applyData[jq].data.group_detail[k].end_time=zb_time[1];

                                }
                            }
                        }
                        that.listShow = applyData
                        console.log(applyData)
                        for (var i = 0; i < applyData.length; i++) {
                            if (applyData[i].type == 1) {
                                this.typeFileList1 = applyData[i]
                                this.preview1[i] = {url: applyData[i].data.imgs[i]}
                                for (var j = 0; j < applyData[i].data.imgs.length; j++) {
                                    this.preview1[j] = {url: applyData[i].data.imgs[j]}
                                }
                            }
                            if (applyData[i].type == 2) {
                                this.typeFileList2 = applyData[i]
                                this.twoTypeNumber = applyData[i].data.group_num
                                for (var b2 = 0; b2 < applyData[i].data.group_detail.length; b2++) {
                                    this.preview2[b2] = []
                                    for (var e2 = 0; e2 < applyData[i].data.group_detail[b2].imgs.length; e2++) {
                                        this.preview2[b2][e2] = {url: applyData[i].data.group_detail[b2].imgs[e2]}
                                    }
                                }
                            }
                            if (applyData[i].type == 3) {
                                this.typeFileList4 = applyData[i]
                                for (var n = 0; n < applyData[i].data.imgs.length; n++) {
                                    this.preview4[n] = {url: applyData[i].data.imgs[n]}
                                }
                            }
                            if (applyData[i].type == 4) {
                                this.typeFileList5 = applyData[i]
                                for (var u = 0; u < applyData[i].data.imgs.length; u++) {
                                    this.preview5[u] = {url: applyData[i].data.imgs[u]}
                                }
                            }
                            if (applyData[i].type == 5) {
                                this.typeFileList3 = applyData[i]
                                for (var m = 0; m < applyData[i].data.imgs.length; m++) {
                                    this.preview3[m] = {url: applyData[i].data.imgs[m]}
                                }
                            }
                            if (applyData[i].type == 6) {
                                this.typeFileList6 = applyData[i]
                                this.sixTypeNumber = applyData[i].data.group_num
                                for (var b = 0; b < applyData[i].data.group_detail.length; b++) {
                                    this.preview6[b] = []
                                    this.preview7[b] = []
                                    for (var e = 0; e < applyData[i].data.group_detail[b].imgs.length; e++) {
                                        this.preview6[b][e] = {url: applyData[i].data.group_detail[b].imgs[e]}
                                        this.preview7[b][0] = {url: applyData[i].data.group_detail[b].sign_table}
                                    }
                                }
                            }
                            console.log(applyData[i].type)
                            if (applyData[i].type == 7) {
                                this.typeFileList7 = applyData[i]
                                this.sevenTypeNumber = applyData[i].data.group_num
                                for (var b3 = 0; b3 < applyData[i].data.group_detail.length; b3++) {
                                    console.log(applyData[i].data.group_detail[b3])
                                    this.preview8 = []
                                    for (var e3 = 0; e3 < applyData[i].data.group_detail[b3].imgs.length; e3++) {
                                        this.preview8[e3] = {url: applyData[i].data.group_detail[b3].imgs[e3]}
                                    }
                                }
                                console.log(this.typeFileList7)
                            }
                        }
                        if (that.co_mode == 1) {
                            that.bankList = res.data.data.bank_info
                            for (var i1 = 0; i1 < that.bankList.length; i1++) {
                                if (that.bankId == that.bankList[i1].id) {
                                    that.bankInfo = that.bankList[i1].account_bank + "(" + that.bankList[i1].account_num + ")";
                                    that.noBank = that.bankList[i1].account_num_real
                                }
                            }
                        }
                    } else {
                        this.$toast(res.data.msg)
                    }
                }).catch(req => {
                    console.log(req)
                    this.$toast.clear()
                })
            },
            getCost() {
                this.$toast({
                    message: '加载中...',
                    forbidClick: true,
                })
                api.apply.getCost(this.openid, this.login, this.number).then(res => {
                    this.$toast.clear()
                    if (res.data.code == 0) {
                        this.realPrice = res.data.resData.real_money
                        this.ratePrice = res.data.resData.fee
                        this.submitPopupShow = true
                    } else {
                        this.$toast(res.data.msg)
                        this.submitPopupShow = true
                    }
                }).catch(req => {
                    console.log(req)
                    this.$toast.clear()
                })
            },
            dateTimes() {
                var time=new Date();
                var  dayNum=new Date(time.getFullYear(),1,0).getDate();
                console.log(dayNum)
                var year=[time.getFullYear()+'年',(time.getFullYear()+1)+'年'];
                var month=['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'];
                var day=[];
                for(var j=0;j<dayNum;j++){
                    day=day.concat((j+1)+'日')
                }
                var hour=["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"];
                var minute=["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"];
                var second=["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31", "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59"];
                var currentTime=[
                    //年
                    {
                        values:year,
                        defaultIndex: 0,
                    },
                    //月
                    {
                        values:month,
                        defaultIndex: 0,
                    },
                    //日
                    {
                        values:day,
                        defaultIndex: 0,
                    },
                    //时
                    {
                        values:hour,
                        defaultIndex: 0,
                    },
                    //分
                    {
                        values:minute,
                        defaultIndex: 0,
                    },
                    //秒
                    {
                        values:second,
                        defaultIndex: 0,
                    },


                ];

                this.currentTime=currentTime;
            },
        },
        created() {
            let id = this.$route.query.id
            this.openid = this.$store.state.openid
            this.applyId = id;
            this.getdata(id)

        },
        mounted() {

        }
    }
</script>

<style lang="less">
    .modifyApply {
        width: 100%;
        padding-top: 1rem;

        .geren {
            width: 100%;
            padding: .34rem;
            background-color: #fff;
            box-sizing: border-box;
            border-top: .02rem solid rgba(229, 227, 227, 0.6);

            ul {
                li {
                    margin-bottom: .36rem;

                    .label {
                        display: inline-block;
                        width: 2.3rem;
                        text-align: right;
                        font-size: .32rem;
                        color: #B5B5B5;
                    }

                    .val {
                        display: inline-block;
                        text-align: right;
                        font-size: .32rem;
                        color: #0D0D0D;
                        padding-left: .42rem;

                        span {
                            font-size: .3rem;
                            color: rgba(241, 82, 35, 1);
                        }
                    }
                }
            }
        }

        .applyPrice {
            width: 100%;
            padding: .34rem 0;
            box-sizing: border-box;
            background-color: #f6f6f6;

            .cardPrice {
                width: 100%;
                border-radius: .2rem;
                background-color: #fff;

                .applyBank {
                    width: 100%;
                    box-sizing: border-box;
                    padding: .4rem;
                    height: 1.3rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .label {
                        font-size: .3rem;
                        color: rgba(119, 118, 118, 1);
                    }

                    .r {
                        width: 70%;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }
                }
            }
        }

        .invoiceWrap {
            width: 100%;
            padding: 0 .24rem;
            box-sizing: border-box;
            background-color: #f6f6f6;

            .invoiceTitle {
                display: flex;
                justify-content: space-between;
                line-height: .6rem;
                font-size: .28rem;
                color: #767575;
            }

            .invoiceBox {
                width: 100%;
                padding: .4rem .6rem;
                box-sizing: border-box;
                background-color: #fff;
                border-radius: .2rem;

                .typeInvoice {
                    ul {
                        display: flex;
                        justify-content: space-between;

                        li {
                            width: 2rem;
                            height: .72rem;
                            border: .02rem solid #f15223;
                            line-height: .72rem;
                            box-sizing: border-box;
                            text-align: center;
                            border-radius: .1rem;

                            img {
                                width: .36rem;
                                height: .36rem;
                                margin-right: .1rem;
                                vertical-align: middle;
                            }

                            span {
                                font-size: .3rem;
                                color: #F15223;
                                vertical-align: middle;
                            }

                            .active {
                                color: #D6D6D6;
                            }
                        }

                        .active {
                            border-color: #D6D6D6;
                        }
                    }
                }

                .uploadImg {
                    width: 100%;
                    padding: .6rem 0;
                    height: 4.1rem;
                    box-sizing: border-box;

                    .uploadBox {
                        width: 2.5rem;
                        position: relative;
                        margin: 0 auto;

                        img {
                            width: 100%;
                        }

                        .van-uploader {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;

                            .van-uploader__upload {
                                width: 100%;

                                .van-uploader__preview {
                                    width: 100%;

                                    .van-uploader__preview-image {
                                        width: 100%;
                                    }
                                }
                            }
                        }

                        .van-uploader__preview-image {
                            width: 2.5rem;
                        }

                        .imgHide {
                            opacity: 0;
                        }
                    }

                    .pdfBox {
                        width: 2.3rem;
                        height: 2.66rem;
                        position: relative;

                        img {
                            width: 100%;
                            height: 100%;
                        }

                        p {
                            position: absolute;
                            bottom: -.8rem;
                            font-size: .22rem;
                            color: #FF6767;
                        }

                        .van-uploader {
                            position: absolute;
                            left: 0;
                            top: 0;
                            width: 100%;
                            height: 100%;

                            .van-uploader__upload {
                                width: 100%;
                                height: 100%;

                            }

                            .van-uploader__file {
                                width: 2.3rem;
                                height: 2.66rem;
                            }
                        }

                        .imgHide {
                            opacity: 0;

                        }
                    }
                }
            }
        }

        .sixTypes {
            width: 100%;
            background-color: #f6f6f6;

            .title {
                padding: .2rem 0 0 .24rem;
                font-size: .28rem;
                color: #767575;
                line-height: .6rem;
            }

            .onetype {
                width: 100%;
                background-color: #fff;
                margin-bottom: .24rem;
                position: relative;

                .wrap {
                    position: relative;
                    padding: 0 0 .86rem .3rem;
                    box-sizing: border-box;
                    overflow: hidden;

                    .typeTitle {
                        span {
                            display: inline-block;
                            width: 2rem;
                            line-height: 1.24rem;
                            font-size: .3rem;
                            color: #000000;
                            font-weight: bold;
                            padding-left: .3rem;
                        }

                        .name {
                            text-align: left;
                            font-weight: 400;
                            color: rgba(74, 72, 72, 1);
                            padding-left: 0;
                        }
                    }

                    .deleteBox {
                        width: 100%;
                        padding-right: .3rem;
                        box-sizing: border-box;

                        .text {
                            width: 80%;
                            color: #FA0202;
                            font-size: .28rem;
                        }

                        .delBtn {
                            display: inline-block;
                            padding: .05rem .2rem;
                            font-size: .28rem;
                            color: #fff;
                            background-color: #F15223;
                            text-align: center;
                            border-radius: .1rem;
                        }
                    }

                    .openImg {
                        position: absolute;
                        right: .5rem;
                        transition: all 1s;
                        width: .4rem;
                        z-index: 20;

                        img {
                            width: 100%;
                        }
                    }

                    .openactive {
                        bottom: .3rem;
                    }

                    .retractActive {
                        top: .4rem;
                        transform: rotate(180deg);
                    }

                    .uploadBox {
                        width: 100%;
                        padding-left: .3rem;
                        box-sizing: border-box;

                        .van-uploader__preview-image {
                            width: 1.76rem;
                        }
                    }

                    .inputWrap {
                        width: 100%;
                        padding: .3rem;
                        box-sizing: border-box;
                        display: flex;
                        align-items: center;
                        justify-content: space-around;

                        .label {
                            font-size: .28rem;
                        }

                        .inputBoxMoney {
                            width: 4rem;
                            height: .8rem;
                            padding: .18rem .3rem;
                            background-color: rgba(255, 248, 248, 1);
                            box-sizing: border-box;
                            border: .02rem solid rgba(255, 228, 228, 1);
                            display: flex;
                            align-items: center;
                            font-size: .28rem;

                            input {
                                border: none;
                                width: 2rem;
                                background-color: rgba(255, 248, 248, 1);
                            }
                        }

                        .bornone {
                            border: none;
                            background: #fff;

                            input {
                                width: 2.5rem;
                                border: none;
                                background: transparent;
                            }
                        }
                    }

                    .remarks {
                        width: 100%;
                        padding: .3rem;
                        box-sizing: border-box;

                        p {
                            font-size: .3rem;
                            margin-bottom: .2rem;
                        }

                        textarea {
                            width: 100%;
                            height: 1.3rem;
                            font-size: .3rem;
                            border-radius: .1rem;
                            border: .02rem solid rgba(229, 227, 227, 0.6);
                            padding: .2rem;
                            box-sizing: border-box;
                        }
                    }
                }

                .wrapHeight {
                    height: 1.22rem;
                }

                .Overlay {
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    background-color: #FEFEFE;
                    opacity: .62;
                    z-index: 10;
                }
            }

            .twoType {
                width: 100%;
                background-color: #fff;
                margin-bottom: .24rem;

                .wrap {
                    position: relative;
                    padding-bottom: .86rem;
                    box-sizing: border-box;
                    overflow: hidden;

                    .typeTitle {
                        padding: 0 0 0 .3rem;
                        box-sizing: border-box;

                        span {
                            display: inline-block;
                            width: 2rem;
                            line-height: 1.24rem;
                            font-size: .3rem;
                            color: #000000;
                            font-weight: bold;
                            padding-left: .3rem;
                        }

                        .name {
                            text-align: left;
                            font-weight: 400;
                            color: #4A4848;
                            padding-left: 0;
                        }

                        input {
                            border: none;
                            width: 2rem;
                            font-size: .28rem;
                        }
                    }

                    .Overlay {
                        position: relative;
                        left: 0;
                        top: 0;
                    }

                    .Overlay:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: #FEFEFE;
                        opacity: 0.62;
                        z-index: 10;
                        width: 100%;
                        height: 100%;
                    }

                    .openImg {
                        position: absolute;
                        right: .5rem;
                        transition: all 1s;
                        width: .4rem;
                        z-index: 20;

                        img {
                            width: 100%;
                        }
                    }

                    .openactive {
                        bottom: .3rem;
                    }

                    .retractActive {
                        top: .4rem;
                        transform: rotate(180deg);
                    }

                    ul {
                        border-top: .02rem solid #f0eeee;
                        padding: 0 .48rem;
                        width: 100%;
                        box-sizing: border-box;

                        li {
                            border-bottom: .02rem solid #f0eeee;
                            padding: .3rem 0;
                            box-sizing: border-box;

                            .deleteBox {
                                width: 100%;
                                padding: 0 .3rem;
                                box-sizing: border-box;

                                .text {
                                    width: 80%;
                                    color: #FA0202;
                                    font-size: .28rem;
                                }

                                .delBtn {
                                    display: inline-block;
                                    padding: .05rem .2rem;
                                    font-size: .28rem;
                                    color: #fff;
                                    background-color: #F15223;
                                    text-align: center;
                                    border-radius: .1rem;
                                }
                            }

                            .name {
                                padding-left: .3rem;
                                color: rgba(99, 79, 198, 1);
                                font-size: .28rem;
                            }

                            .inputbox {
                                width: 100%;
                                position: relative;
                                padding-left: .84rem;
                                margin: .32rem 0 .28rem 0;
                                box-sizing: border-box;
                                font-size: .28rem;

                                .text {
                                    position: relative;
                                    width: 1.56rem;
                                    display: inline-block;
                                }

                                .text::after {
                                    position: absolute;
                                    content: "*";
                                    right: .51rem;
                                    top: .08rem;
                                    font-size: .28rem;
                                    font-weight: bold;
                                    color: red;
                                }

                                .text1 {
                                    position: relative;
                                    width: 1.9rem;
                                    display: inline-block;
                                }

                                .text1::after {
                                    position: absolute;
                                    content: "*";
                                    right: -.05rem;
                                    top: .08rem;
                                    font-size: .28rem;
                                    font-weight: bold;
                                    color: red;
                                }

                                .text5 {
                                    position: relative;
                                    width: 2rem;
                                    display: inline-block;
                                }

                                .text5::after {
                                    position: absolute;
                                    content: "*";
                                    right: .51rem;
                                    top: .08rem;
                                    font-size: .28rem;
                                    font-weight: bold;
                                    color: red;
                                }

                                .text52 {
                                    position: relative;
                                    width: 2rem;
                                    display: inline-block;
                                }

                                .text52::after {
                                    position: absolute;
                                    content: "*";
                                    right: -.05rem;
                                    top: .08rem;
                                    font-size: .28rem;
                                    font-weight: bold;
                                    color: red;
                                }

                                input {
                                    border: none;
                                    margin-left: 0.2rem;
                                    width: 3rem;
                                    font-size: .28rem;
                                }
                            }

                            .inputbox::after {
                                position: absolute;
                                content: "";
                                left: .46rem;
                                top: .16rem;
                                width: .1rem;
                                height: .1rem;
                                background-color: rgba(255, 134, 92, 1);
                                border-radius: 50%;
                            }

                            .uploadBox {
                                width: 100%;
                                padding-left: .3rem;
                                box-sizing: border-box;

                                .van-uploader__preview-image {
                                    width: 1.76rem;
                                }
                            }

                            .radioWrap {
                                padding: .3rem;
                                display: flex;
                                justify-content: space-between;

                                .activeIcon {
                                    display: inline-block;
                                    width: .32rem;
                                    height: .32rem;
                                    border-radius: 50%;
                                    border: .02rem solid rgba(133, 128, 128, 1);
                                    box-sizing: border-box;
                                }

                                .inactiveIcon {
                                    display: inline-block;
                                    position: relative;
                                    width: .32rem;
                                    height: .32rem;
                                    border-radius: 50%;
                                    box-sizing: border-box;
                                    background: rgba(241, 82, 35, 1);
                                }

                                .inactiveIcon::after {
                                    position: absolute;
                                    content: '';
                                    width: .28rem;
                                    height: .28rem;
                                    border-radius: 50%;
                                    box-sizing: border-box;
                                    background: rgba(241, 82, 35, 1);
                                    border: .04rem solid #fff;
                                    left: 0;
                                    right: 0;
                                    margin: 0 auto;
                                    top: .02rem;
                                }
                            }

                            .inputWrap {
                                width: 100%;
                                padding: .3rem;
                                box-sizing: border-box;
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                font-size: .28rem;

                                .inputBoxMoney {
                                    width: 4rem;
                                    height: .8rem;
                                    padding: .18rem .3rem;
                                    background-color: rgba(255, 248, 248, 1);
                                    box-sizing: border-box;
                                    border: .02rem solid rgba(255, 228, 228, 1);
                                    display: flex;
                                    align-items: center;
                                    font-size: .28rem;

                                    input {
                                        border: none;
                                        width: 2.5rem;
                                        background-color: rgba(255, 248, 248, 1);
                                    }
                                }
                            }
                        }

                        .Overlay {
                            position: relative;
                            left: 0;
                            top: 0;
                        }

                        .Overlay:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-color: #FEFEFE;
                            opacity: 0.62;
                            z-index: 10;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .remarks {
                        width: 100%;
                        padding: .3rem;
                        box-sizing: border-box;

                        p {
                            font-size: .3rem;
                            margin-bottom: .2rem;
                        }

                        textarea {
                            width: 100%;
                            height: 1.3rem;
                            font-size: .3rem;
                            border-radius: .1rem;
                            border: .02rem solid rgba(229, 227, 227, 0.6);
                            padding: .2rem;
                            box-sizing: border-box;
                        }
                    }
                }

                .wrapHeight {
                    height: 2.6rem;
                }
            }

            .threeType {
                width: 100%;
                background-color: #fff;
                margin-bottom: .24rem;

                .wrap {
                    position: relative;
                    padding-bottom: .86rem;
                    box-sizing: border-box;
                    overflow: hidden;

                    .typeTitle {
                        padding: 0 0 0 .3rem;
                        box-sizing: border-box;

                        span {
                            display: inline-block;
                            width: 2rem;
                            line-height: 1.24rem;
                            font-size: .3rem;
                            color: #000000;
                            font-weight: bold;
                            padding-left: .3rem;
                        }

                        .name {
                            text-align: left;
                            font-weight: 400;
                            color: #4A4848;
                            padding-left: 0;
                        }

                        input {
                            border: none;
                            width: 2rem;
                            font-size: .28rem;
                        }

                        .activeIcon {
                            display: inline-block;
                            width: .32rem;
                            height: .32rem;
                            border-radius: 50%;
                            border: .02rem solid rgba(133, 128, 128, 1);
                            box-sizing: border-box;
                        }

                        .inactiveIcon {
                            display: inline-block;
                            position: relative;
                            width: .32rem;
                            height: .32rem;
                            border-radius: 50%;
                            box-sizing: border-box;
                            background: rgba(241, 82, 35, 1);
                        }

                        .inactiveIcon::after {
                            position: absolute;
                            content: '';
                            width: .28rem;
                            height: .28rem;
                            border-radius: 50%;
                            box-sizing: border-box;
                            background: rgba(241, 82, 35, 1);
                            border: .04rem solid #fff;
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                            top: .02rem;
                        }

                        .radioWrap {
                            justify-content: space-around;
                        }

                        .van-radio__label {
                            width: auto;
                        }
                    }

                    .Overlay {
                        position: relative;
                        left: 0;
                        top: 0;
                    }

                    .Overlay:after {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        background-color: #FEFEFE;
                        opacity: 0.62;
                        z-index: 10;
                        width: 100%;
                        height: 100%;
                    }

                    .openImg {
                        position: absolute;
                        right: .5rem;
                        transition: all 1s;
                        width: .4rem;
                        z-index: 20;

                        img {
                            width: 100%;
                        }
                    }

                    .openactive {
                        bottom: .3rem;
                    }

                    .retractActive {
                        top: .4rem;
                        transform: rotate(180deg);
                    }

                    ul {
                        border-top: .02rem solid #f0eeee;
                        padding: 0 .48rem;
                        width: 100%;
                        box-sizing: border-box;

                        li {
                            border-bottom: .02rem solid #f0eeee;
                            padding: .3rem 0;
                            box-sizing: border-box;

                            .deleteBox {
                                width: 100%;
                                padding-right: .3rem;
                                box-sizing: border-box;

                                .text {
                                    width: 80%;
                                    color: #FA0202;
                                    font-size: .28rem;
                                }

                                .delBtn {
                                    display: inline-block;
                                    padding: .05rem .2rem;
                                    font-size: .28rem;
                                    color: #fff;
                                    background-color: #F15223;
                                    text-align: center;
                                    border-radius: .1rem;
                                }
                            }

                            .name {
                                padding-left: .3rem;
                                color: rgba(99, 79, 198, 1);
                                font-size: .28rem;
                            }

                            .inputbox {
                                width: 100%;
                                position: relative;
                                padding-left: .84rem;
                                margin: .32rem 0 .28rem 0;
                                box-sizing: border-box;
                                font-size: .28rem;

                                .text {
                                    position: relative;
                                    width: 1.56rem;
                                    display: inline-block;
                                }

                                .text::after {
                                    position: absolute;
                                    content: "*";
                                    right: 0rem;
                                    top: .12rem;
                                    font-size: .28rem;
                                    font-weight: bold;
                                    color: red;
                                }

                                input {
                                    border: none;
                                    margin-left: .68rem;
                                    width: 2.3rem;
                                    font-size: .28rem;
                                }
                            }

                            .inputbox::after {
                                position: absolute;
                                content: "";
                                left: .46rem;
                                top: .16rem;
                                width: .1rem;
                                height: .1rem;
                                background-color: rgba(255, 134, 92, 1);
                                border-radius: 50%;
                            }

                            .uploadBox {
                                width: 100%;
                                padding-left: .3rem;
                                box-sizing: border-box;

                                .van-uploader__preview-image {
                                    width: 1.76rem;
                                }

                                p {
                                    font-size: .3rem;
                                    line-height: .6rem;
                                    font-weight: bold;
                                }
                            }

                            .inputWrap {
                                width: 100%;
                                padding: .3rem;
                                box-sizing: border-box;
                                display: flex;
                                align-items: center;
                                justify-content: space-around;
                                font-size: .28rem;

                                .inputBoxMoney {
                                    width: 4rem;
                                    height: .8rem;
                                    padding: .18rem .3rem;
                                    background-color: rgba(255, 248, 248, 1);
                                    box-sizing: border-box;
                                    border: .02rem solid rgba(255, 228, 228, 1);
                                    display: flex;
                                    align-items: center;
                                    font-size: .28rem;

                                    input {
                                        border: none;
                                        width: 2.5rem;
                                        background-color: rgba(255, 248, 248, 1);
                                    }
                                }
                            }
                        }

                        .Overlay {
                            position: relative;
                            left: 0;
                            top: 0;
                        }

                        .Overlay:after {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-color: #FEFEFE;
                            opacity: 0.62;
                            z-index: 10;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    .remarks {
                        width: 100%;
                        padding: .3rem;
                        box-sizing: border-box;

                        p {
                            font-size: .3rem;
                            margin-bottom: .2rem;
                        }

                        textarea {
                            width: 100%;
                            height: 1.3rem;
                            font-size: .3rem;
                            border-radius: .1rem;
                            border: .02rem solid rgba(229, 227, 227, 0.6);
                            padding: .2rem;
                            box-sizing: border-box;
                        }
                    }
                }

                .wrapHeight {
                    height: 3.9rem;
                }
            }

            .desc {
                font-size: .26rem;
                color: #C9C9C9;
                padding-left: .3rem;

                span {
                    color: rgba(241, 82, 35, 1);
                }
            }
        }

        .fixedBot {
            position: fixed;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1.2rem;
            display: flex;
            align-items: center;
            background: #fff;
            font-size: .32rem;
            padding: 0 0.52rem;
            box-sizing: border-box;
            border-top: .02rem solid rgba(220, 220, 220, 1);
            z-index: 30;

            .wrap {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;

                .left {
                    span {
                        color: rgba(241, 82, 35, 1);
                    }
                }
            }
        }

        .bankList {
            width: 100%;
            padding-top: 1rem;

            li {
                width: 100%;
                box-sizing: border-box;
                padding: 0 .8rem;
                line-height: 1.1rem;
                font-size: .32rem;
                color: #000;
                border-bottom: .02rem solid rgba(229, 227, 227, 0.6);
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: space-around;
            }
        }

        .submitPopupBox {
            padding: 0 .3rem .3rem .3rem;
            box-sizing: border-box;

            .title {
                color: #000;
                text-align: center;

                .name {
                    font-size: .32rem;
                }

                .price {
                    font-size: .44rem;
                    margin: .3rem 0 .5rem 0;
                }
            }

            ul {
                li {
                    display: flex;
                    justify-content: space-between;
                    font-size: .28rem;
                    color: #000;
                    margin-bottom: .26rem;
                }
            }

            .tip {
                font-size: .22rem;
                color: rgba(253, 32, 32, 1);
                width: 100%;
                text-align: center;
                margin: .46rem 0 .7rem 0;
            }

            .submitBtn {
                width: 3.6rem;
                line-height: .6rem;
                background-color: rgba(241, 82, 35, 1);
                border-radius: .1rem;
                text-align: center;
                margin: 0 auto;
                color: #fff;
            }
        }

    }
</style>